import {
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  VStack,
  Text,
  useToken,
  AlertIcon,
  Alert,
  Button,
  Spacer,
} from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Props } from "./Navigation.types";
import { FiChevronDown, FiMenu } from "react-icons/fi";
import { BsGearFill } from "react-icons/bs";
import Logo from "../Logo";
import { PortalsContext } from "../../contexts/PortalsContext";
import { t } from "i18next";
import { translations } from "../../i18n/translations";
import { useLogout } from "../../utils/logout";
import { useUser } from "../../api/modules/User";
import { getProfileImageUrl } from "../../utils/getProfileImageUrl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Routing } from "../../constants/routing";

export const Navigation = ({ onOpen, ...rest }: Props) => {
  const location = useLocation();
  const handleLogout = useLogout();
  const navigate = useNavigate();
  const { data, isLoading } = useUser();
  const { isStripeComplete } = data || {};
  const [inAccountWizard, setInAccountWizard] = useState(false);
  const handleNavigateProfile = useCallback(() => {
    navigate(Routing.Profile);
  }, [navigate]);
  const [gray100, gray500, gray700] = useToken("colors", [
    "gray.100",
    "gray.500",
    "gray.700",
  ]);
  const { breadcrumbsRef } = useContext(PortalsContext);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const hasQueryString = queryParams.keys().next().done === false;
    if (hasQueryString) {
      const accountWizard = queryParams.get("account-wizard");
      if (accountWizard === "true") {
        setInAccountWizard(true);
      } else {
        setInAccountWizard(false);
      }
    }
  }, [location]);

  const handleComplete = useCallback(async () => {
    navigate(`${Routing.Profile}?account-wizard=true`);
  }, [navigate]);

  return (
    <>
      <Flex
        alignItems="center"
        bg={{ base: "gray.800", md: "gray.50" }}
        height="16"
        justifyContent="space-between"
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 4 }}
        {...rest}
      >
        <Flex display={{ base: "none", md: "block" }} fontSize="12">
          <div ref={breadcrumbsRef} />
          {/*<Text ref={pageTitleRef} fontSize="md" fontWeight={600} />*/}
        </Flex>
        <IconButton
          aria-label="open menu"
          display={{ base: "flex", md: "none" }}
          icon={<FiMenu color={gray100} />}
          variant="outline"
          onClick={onOpen}
        />
        <Box display={{ base: "block", md: "none" }}>
          <Logo width="100px" />
        </Box>
        <HStack spacing={{ base: "0", md: "6" }}>
          <Flex alignItems={"center"}>
            <Menu>
              <MenuButton
                _focus={{ boxShadow: "none" }}
                py={2}
                transition="all 0.3s"
              >
                <HStack>
                  <Avatar size="sm" src={getProfileImageUrl(data)} />
                  <VStack
                    alignItems="flex-start"
                    display={{ base: "none", md: "flex" }}
                    ml="2"
                    spacing="1px"
                  >
                    <Text fontSize="sm">{`${data?.firstName || ""} ${
                      data?.lastName || ""
                    }`}</Text>
                  </VStack>
                  <Box display={{ base: "none", md: "flex" }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList bg="gray.800" borderColor={gray500} color={gray100}>
                <MenuItem
                  _focus={{ bg: "gray.700" }}
                  bg="gray.900"
                  onClick={handleNavigateProfile}
                >
                  {t<string>(translations.common.profileMenu.profile)}
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  _focus={{ bg: "gray.700" }}
                  bg="gray.900"
                  onClick={handleLogout}
                >
                  {t<string>(translations.common.profileMenu.signOut)}
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <Flex>
            {/*<IconButton*/}
            {/*  _active={{ backgroundColor: "transparent" }}*/}
            {/*  _hover={{ backgroundColor: "transparent" }}*/}
            {/*  aria-label="open menu"*/}
            {/*  display={{ base: "flex", md: "none" }}*/}
            {/*  icon={<BsBellFill color={gray100} />}*/}
            {/*  size="md"*/}
            {/*  variant="ghost"*/}
            {/*/>*/}
            {/*<IconButton*/}
            {/*  aria-label="open menu"*/}
            {/*  display={{ base: "none", md: "flex" }}*/}
            {/*  icon={<BsBellFill color={gray700} />}*/}
            {/*  size="md"*/}
            {/*  variant="ghost"*/}
            {/*/>*/}
            <Link to={Routing.Settings.LoginInformation.Absolute}>
              <IconButton
                _active={{ backgroundColor: "transparent" }}
                _hover={{ backgroundColor: "transparent" }}
                aria-label="open menu"
                display={{ base: "flex", md: "none" }}
                icon={<BsGearFill color={gray100} />}
                size="md"
                variant="ghost"
              />
            </Link>
            <Link to={Routing.Settings.LoginInformation.Absolute}>
              <IconButton
                aria-label="open menu"
                display={{ base: "none", md: "flex" }}
                icon={<BsGearFill color={gray700} />}
                size="md"
                variant="ghost"
              />
            </Link>
          </Flex>
        </HStack>
      </Flex>
      {!isStripeComplete && !inAccountWizard && !isLoading && (
        <Flex
          flexDirection={{ base: "column", md: "row" }}
          ml={{ base: 0, md: 60 }}
          px={{ base: 4, md: 4 }}
        >
          <Alert
            alignItems="center"
            borderRadius="12px"
            color="#B54708"
            height={["auto", "44px"]}
            justifyContent={{ base: "center", md: "space-between" }}
            status="warning"
            textAlign={{ base: "center", md: "left" }}
            variant="subtle"
            w="100%"
          >
            <AlertIcon />
            <Flex
              alignItems="center"
              flexDirection={{ base: "column", md: "row" }}
              w="100%"
            >
              <Flex
                alignItems="center"
                flexDirection={{ base: "column", md: "row" }}
                gap={1}
              >
                <b>Profile Incomplete</b>
                <Box display={{ base: "block", md: "inline" }}>
                  Please complete your profile to enable full access
                </Box>
              </Flex>
              <Spacer display={{ base: "none", md: "block" }} />
              <Button
                color="#B54708"
                mt={{ base: 4, md: 0 }}
                size="sm"
                variant="ghost"
                onClick={() => handleComplete()}
              >
                Complete Now
              </Button>
            </Flex>
          </Alert>
        </Flex>
      )}
    </>
  );
};
