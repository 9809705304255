import React from "react";
import {
  Text,
  Flex,
  Box,
  Spacer,
  Link,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Icon,
} from "@chakra-ui/react";
import { translations } from "../../i18n/translations";
import { t } from "i18next";
import { Logo } from "../../components/LogoBlack/LogoBlack";
import { Trans } from "react-i18next";
import { AiFillApple as AppleIcon } from "react-icons/ai";
import { IoIosCheckmarkCircle as CheckIcon } from "react-icons/io";
import { NavLink as RouterLink } from "react-router-dom";
import { PresentationProps } from "./Signup.types";
import { Routing } from "../../constants/routing";

export const SignupPresentation = (props: PresentationProps) => {
  const { register, onSubmit, errors, isSubmitting, onAppleSignIn } = props;
  const screen = translations.screens.signup;

  return (
    <>
      <Flex gap="2" p={2}>
        <Box gap="2">
          <Logo />
        </Box>
        <Spacer></Spacer>
        <Flex direction="row" gap={1}>
          <Text>Welcome, </Text>
          <Link
            _focus={{ boxShadow: "none" }}
            as={RouterLink}
            style={{ textDecoration: "none" }}
            to={Routing.Login}
          >
            <Text color="#FB7C23" fontSize={14} fontWeight={600} mt="2px">
              Log in
            </Text>
          </Link>
        </Flex>
      </Flex>
      <Flex
        alignItems={["center", "center"]}
        flexDirection="column"
        height="85vh"
        justifyContent="center"
      >
        <Flex alignItems={["center"]} flexDirection="column">
          <Box
            backgroundColor="#FFFAF7"
            borderRadius={999}
            height="24px"
            padding="2px"
            textAlign="center"
            width="73px"
          >
            <Text color="#FB7C23" fontSize="12px" fontWeight={700}>
              {t<string>(screen.title)}
            </Text>
          </Box>
          <Text
            color="Gray/900"
            fontSize={{ base: "24px", sm: "24px", md: "24px", lg: "36px" }}
            fontWeight={700}
            lineHeight={{ base: "24px", sm: "24px", md: "24px", lg: "50px" }}
            minWidth={{ base: "150%", sm: "150%", md: "150%", lg: "360px" }}
            textAlign="center"
          >
            {t<string>(screen.heading)}
          </Text>
        </Flex>
        <Flex mt="32px">
          <form
            style={{
              width: "360px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onSubmit={onSubmit}
          >
            <FormControl isInvalid={Boolean(errors.email?.message)}>
              <FormLabel
                color="#344054"
                fontSize={14}
                fontWeight={500}
                htmlFor="name"
              >
                {t<string>(screen.form.email)}
              </FormLabel>
              <Input
                autoComplete="off"
                height="44px"
                id="email"
                {...register("email", {
                  required: "This is required",
                  minLength: {
                    value: 4,
                    message: "Minimum length should be 4",
                  },
                })}
                placeholder="Enter your work email"
                width="360px"
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.password?.message)}>
              <FormLabel
                color="#344054"
                fontSize={14}
                fontWeight={500}
                htmlFor="name"
                mt="20px"
              >
                {t<string>(screen.form.password)}
              </FormLabel>
              <Input
                autoComplete="off"
                height="44px"
                id="password"
                type="password"
                {...register("password", {
                  required: "This is required",
                  minLength: {
                    value: 8,
                    message: "Must be at least 8 characters",
                  },
                  pattern: {
                    value: /^(?=.*[!@#$%^&*])/,
                    message: "Must contain one special character",
                  },
                })}
                placeholder="Create a password"
                width="360px"
              />
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
            <Flex alignItems="flex-start" gap="1" mt="20px" width="100%">
              <Icon as={CheckIcon} color="#D0D5DD" fontSize="2xl" />
              <Text color="#475467" fontSize="16px" fontWeight={400}>
                Must be at least 8 characters
              </Text>
            </Flex>
            <Flex alignItems="flex-start" gap="1" mt="12px" width="100%">
              <Icon as={CheckIcon} color="#D0D5DD" fontSize="2xl" />
              <Text color="#475467" fontSize="16px" fontWeight={400}>
                Must contain one special character
              </Text>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" mt="24px">
              <Button
                _hover={{ bg: "orange.600" }}
                backgroundColor="#FB7C23"
                color="white"
                // colorScheme="orange"
                isLoading={isSubmitting}
                type="submit"
                width="360px"
              >
                {t<string>(screen.form.signup)}
              </Button>
            </Flex>
            <Flex
              alignItems={["center"]}
              flexDirection="column"
              gap={2}
              mt="16px"
            >
              <Button
                fontFamily='"Google Sans",arial,sans-serif'
                fontSize="15px"
                fontWeight={500}
                gap={1}
                mb="6px"
                size="md"
                variant="outline"
                width="360px"
                onClick={onAppleSignIn}
              >
                <Box ml="-16px">
                  <AppleIcon fontSize="26px" />
                </Box>
                <Flex>{t<string>(screen.form.signInWithApple)}</Flex>
              </Button>
              <div id="google-button" />
            </Flex>
            <Flex alignItems="center" gap={1} mt="32px">
              <Text color="gray.600" fontSize={14} fontWeight={400}>
                Already have an account?
              </Text>
              <Link
                _focus={{ boxShadow: "none" }}
                as={RouterLink}
                style={{ textDecoration: "none" }}
                to={Routing.Login}
              >
                <Text color="#FB7C23" fontSize={14} fontWeight={600}>
                  Log in
                </Text>
              </Link>
            </Flex>
            <Flex
              alignSelf="center"
              display="inline-block"
              fontSize="12px"
              justifyContent="center"
              lineHeight="18px"
              mt={6}
              // px={6}
              textAlign="center"
              width="80%"
            >
              <Trans i18nKey="screens.login.acceptTerms">
                By continuing you agree to the
                <Link
                  color="#FB7C23"
                  href="https://www.nationalonsite.com/legal/privacy"
                  target="_blank"
                >
                  privacy policy
                </Link>
                <Link
                  color="#FB7C23"
                  href="https://www.nationalonsite.com/legal/terms-of-use"
                  target="_blank"
                >
                  terms of use
                </Link>
                <Link
                  color="#FB7C23"
                  href="https://www.nationalonsite.com/legal/terms-of-service"
                  target="_blank"
                >
                  terms of service
                </Link>
              </Trans>
            </Flex>
          </form>
        </Flex>
      </Flex>
    </>
  );
};
