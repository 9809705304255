import { useMutation, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { postUploadProfilePhoto } from "./postUploadProfilePhoto";
import { TUploadProfilePhoto } from "./types";
import { translations } from "../../../i18n/translations";
import { Queries } from "../../../constants/queries";

export const useUploadProfilePhoto = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, TUploadProfilePhoto>(
    (payload) => postUploadProfilePhoto(payload),
    {
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.photo_upload_error),
          status: "error",
          position: "top-right",
        });
      },
      onSuccess: (data, variables) => {
        if (variables.isCompanyLogo) {
          queryClient.invalidateQueries(Queries.Company(variables.companyId));
        } else {
          queryClient.invalidateQueries(["user"]);
        }
      },
    }
  );
};
