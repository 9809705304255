import React, { useCallback, useState } from "react";
import { Box, Button, Checkbox, Flex, Text, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { Props } from "./CompanyDetails.types";
import { CompanyData } from "../CompanyData";
import { CompanyAdmins } from "../CompanyAdmins";
import { Payments } from "../Payments";
import { useGetStripeLink } from "../../../../api/modules/GetStripeLink";
import { useNavigate } from "react-router-dom";
import { Routing } from "../../../../constants/routing";

export const CompanyDetailsTab = (props: Props) => {
  const {
    companyLogoUrl,
    data,
    formData,
    onEditField,
    onEditCheckboxField,
    inAccountWizard,
    isEditMode,
    isEditable,
    isStripeConnected,
    isUpdatingCompany,
    onCompanyBack,
    onCancelEditMode,
    onSaveCompany,
    onSetEditMode,
  } = props;
  const screen = translations.screens.profile;
  const navigate = useNavigate();
  const toast = useToast();
  const { mutateAsync, isLoading } = useGetStripeLink();

  const [isNextClicked, setIsNextClicked] = useState(false);
  const handleClickCTA = useCallback(async () => {
    setIsNextClicked(true);
    await onSaveCompany();
    const link = await mutateAsync();
    window.location.assign(link);
    setIsNextClicked(false);
  }, [mutateAsync, onSaveCompany]);

  const handleFinish = useCallback(async () => {
    const id = "completed";
    if (!toast.isActive(id)) {
      toast({
        id,
        title: "",
        description: "Account Completed",
        position: "top-right",
        status: "success",
        isClosable: true,
        duration: 10000,
        variant: "subtle",
        containerStyle: { color: "#067647" },
      });
    }
    navigate(Routing.Team);
  }, [navigate, toast]);

  return (
    <>
      <br />
      <Flex justifyContent="space-between">
        <Text fontSize="lg" fontWeight={600}>
          {t<string>(screen.companyInformation)}
        </Text>
        <Flex justifyContent="flex-end">
          {isEditMode && !inAccountWizard && (
            <>
              <Button
                colorScheme="blue"
                isLoading={isUpdatingCompany}
                size="sm"
                variant="outline"
                onClick={onCancelEditMode}
              >
                {t<string>(screen.cancel)}
              </Button>
              <Box ml={3}>
                <Button
                  colorScheme="blue"
                  isLoading={isUpdatingCompany}
                  size="sm"
                  onClick={onSaveCompany}
                >
                  {t<string>(screen.save)}
                </Button>
              </Box>
            </>
          )}
          {!isEditMode && isEditable && !inAccountWizard && (
            <Button colorScheme="blue" size="sm" onClick={onSetEditMode}>
              {t<string>(screen.edit)}
            </Button>
          )}
          {inAccountWizard && (
            <Flex gap={2}>
              <Button
                colorScheme="blue"
                size="sm"
                variant="outline"
                onClick={onCompanyBack}
              >
                {t<string>(screen.back)}
              </Button>
              <Button
                colorScheme="blue"
                isLoading={isUpdatingCompany && !isNextClicked}
                size="sm"
                onClick={onSaveCompany}
              >
                {t<string>(screen.save)}
              </Button>
              <Button
                colorScheme="blue"
                isLoading={(isUpdatingCompany && isNextClicked) || isLoading}
                size="sm"
                onClick={isStripeConnected ? handleFinish : handleClickCTA}
              >
                {t<string>(screen.next)}
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
      <br />
      <CompanyData
        companyLogoUrl={companyLogoUrl}
        data={data}
        formData={formData}
        isEditMode={isEditMode || inAccountWizard}
        isUpdatingCompany={isUpdatingCompany}
        onEditField={onEditField}
      />
      <br />
      {!inAccountWizard && (
        <Box>
          <Payments />
          <br />
          <Text fontSize="lg" fontWeight={600}>
            {t<string>(screen.insurance)}
          </Text>
          <br />
          <Text color="gray.500">{t<string>(screen.insuranceDescription)}</Text>
          <br />
          <Checkbox
            checked={formData.haveOwnInsurance}
            defaultChecked={data?.haveOwnInsurance}
            isDisabled={!isEditMode}
            onChange={onEditCheckboxField("haveOwnInsurance")}
          >
            {t<string>(screen.hasInsurance)}
          </Checkbox>
          <br />
          <br />
          <Text fontSize="lg" fontWeight={600}>
            {t<string>(screen.companyAdmins)}
          </Text>
          <CompanyAdmins data={data?.companyAdmins || []} />
        </Box>
      )}
    </>
  );
};
