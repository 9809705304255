import React from "react";
import Layout from "../../../../components/Layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { FiChevronDown as ChevronDownIcon } from "react-icons/fi";
import { t } from "i18next";
import { Routing } from "../../../../constants/routing";
import { translations } from "../../../../i18n/translations";
import { PresentationProps } from "./Profile.types";
import { Link as RouterLink } from "react-router-dom";
import { PersonalJobs } from "../PersonalJobs";
import { getRolesDisplayNames } from "../../../../utils/User/getRolesLabel";
import { UpdateRoles } from "../../UpdateRoles";
import { RemoveFromTeam } from "../../../RemoveFromTeamModal";
import { PersonalDetails } from "../PersonalDetails";
import { CompanyDetails } from "../CompanyDetails";
import FullPageContent from "../../../../components/FullPageContent";
import { AccessLevelGuard } from "../../../../components/AccessLevelGuard";
import { Roles } from "../../../../constants/roles";
import { ServiceDetails } from "../ServiceDetails";
import { PersonHeader } from "../../../../components/PersonHeader";
import { WorkLocations } from "../WorkLocations";
import { Payments } from "../Payments";

export const ProfilePresentation = (props: PresentationProps) => {
  const screen = translations.screens.profile;
  const {
    activeServiceOptions,
    companyData,
    statics,
    isEditable,
    pageTitleRef,
    breadcrumbsRef,
    isServiceDetailsAvailable,
    isUpdatingCompanyJobTypes,
    isLoading,
    isUpdatingProfile,
    isUpdatingProfileNext,
    data,
    isEditMode,
    serviceOptions,
    onSetEditMode,
    onCancelEditMode,
    onEditField,
    onSaveProfile,
    formData,
    removeFromTeam,
    updateRolesModal,
    companyFormData,
    isCompanyEditable,
    isEditCompanyMode,
    isUpdatingCompany,
    isEditServiceMode,
    inAccountWizard,
    isStripeConnected,
    tabIndex,
    serviceFormData,
    onTabChange,
    onCompanyBack,
    onCancelServiceEditMode,
    onEditServiceField,
    onEditServiceLocationField,
    onSetEditServiceMode,
    onChangeServiceType,
    onSetEditCompanyMode,
    onPersonalNext,
    onCancelCompanyEditMode,
    onEditCompanyField,
    onEditCompanyCheckboxField,
    onSaveCompany,
    onSaveService,
    onRemoveCompanyLocation,
    onAddCompanyLocation,
  } = props;
  const {
    firstName,
    lastName,
    jobTitle,
    rating,
    company,
    roles,
    companyId,
    email,
    phoneNumber,
    profilePicUrl,
  } = data || {};
  const { companyLogoUrl } = companyData || {};

  return (
    <>
      <Layout>
        <Portal containerRef={pageTitleRef}>
          {t<string>(screen.pageTitle)}
        </Portal>
        <Portal containerRef={breadcrumbsRef}>
          <Breadcrumb fontWeight={500}>
            <BreadcrumbItem color="gray.400">
              <BreadcrumbLink as={RouterLink} to={Routing.Root}>
                {t<string>(screen.breadcrumbs.root)}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <div>{t<string>(screen.breadcrumbs.profile)}</div>
            </BreadcrumbItem>
          </Breadcrumb>
        </Portal>
        <FullPageContent>
          <Text fontSize="lg" fontWeight={700}>
            {t<string>(screen.pageTitle)}
          </Text>
          <br />
          {isLoading && (
            <Flex justifyContent="center" mt={4}>
              <Spinner />
            </Flex>
          )}
          {!isLoading && (
            <Flex flex={1} flexDirection="column">
              <Flex alignItems="center" justifyContent="space-between">
                <PersonHeader
                  email={email}
                  firstName={firstName}
                  jobTitle={jobTitle}
                  lastName={lastName}
                  phone={phoneNumber}
                  profilePicUrl={profilePicUrl}
                  rating={rating}
                  roles={getRolesDisplayNames(roles, statics).join(", ")}
                />
                <Flex>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      size="sm"
                    >
                      {t<string>(screen.options)}
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={updateRolesModal.onOpen}>
                        {t<string>(screen.changeRoles)}
                      </MenuItem>
                      {companyId && (
                        <MenuItem onClick={removeFromTeam.onOpen}>
                          {t<string>(screen.removeFromTeam)}
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                </Flex>
              </Flex>
              <br />
              <Divider />
              <br />
              <Tabs
                display="flex"
                flex={1}
                flexDirection="column"
                index={tabIndex}
                onChange={onTabChange}
              >
                <TabList>
                  <Tab>{t<string>(screen.personalDetails)}</Tab>
                  {isServiceDetailsAvailable && !inAccountWizard && (
                    // <AccessLevelGuard permittedRoles={[Roles.ContractorAdmin]}>
                    <Tab>{t<string>(screen.serviceDetails)}</Tab>
                    // </AccessLevelGuard>
                  )}
                  <Tab>{t<string>(screen.companyInformation)}</Tab>
                  {!inAccountWizard && <Tab>{t<string>(screen.jobs)}</Tab>}
                </TabList>
                <TabPanels display="flex" flex={1} flexDirection="column">
                  <TabPanel>
                    <PersonalDetails
                      data={data}
                      formData={formData}
                      inAccountWizard={inAccountWizard}
                      isEditMode={isEditMode}
                      isEditable={isEditable}
                      isUpdatingProfile={isUpdatingProfile}
                      isUpdatingProfileNext={isUpdatingProfileNext}
                      onCancelEditMode={onCancelEditMode}
                      onEditField={onEditField}
                      onPersonalNext={onPersonalNext}
                      onSaveProfile={onSaveProfile}
                      onSetEditMode={onSetEditMode}
                    />
                  </TabPanel>
                  {isServiceDetailsAvailable && !inAccountWizard && (
                    <AccessLevelGuard permittedRoles={[Roles.ContractorAdmin]}>
                      <TabPanel display="flex" flex={1} flexDirection="column">
                        <WorkLocations
                          companyData={companyData}
                          formData={serviceFormData}
                          isEditMode={isEditServiceMode}
                          isEditable={isEditable}
                          isLoading={isUpdatingCompany}
                          onAddCompanyLocation={onAddCompanyLocation}
                          onCancelEditMode={onCancelServiceEditMode}
                          onEditField={onEditServiceField}
                          onEditLocationField={onEditServiceLocationField}
                          onRemoveCompanyLocation={onRemoveCompanyLocation}
                          onSave={onSaveService}
                          onSetEditMode={onSetEditServiceMode}
                        />
                        <ServiceDetails
                          activeServiceOptions={activeServiceOptions}
                          isUpdatingCompanyJobTypes={isUpdatingCompanyJobTypes}
                          options={serviceOptions}
                          onChangeServiceType={onChangeServiceType}
                        />
                      </TabPanel>
                    </AccessLevelGuard>
                  )}
                  <TabPanel>
                    <CompanyDetails
                      companyLogoUrl={companyLogoUrl as string}
                      data={company}
                      formData={companyFormData}
                      inAccountWizard={inAccountWizard}
                      isEditMode={isEditCompanyMode}
                      isEditable={isCompanyEditable}
                      isStripeConnected={isStripeConnected}
                      isUpdatingCompany={isUpdatingCompany}
                      onCancelEditMode={onCancelCompanyEditMode}
                      onCompanyBack={onCompanyBack}
                      onEditCheckboxField={onEditCompanyCheckboxField}
                      onEditField={onEditCompanyField}
                      onSaveCompany={onSaveCompany}
                      onSetEditMode={onSetEditCompanyMode}
                    />
                    <ServiceDetails
                      activeServiceOptions={activeServiceOptions}
                      isUpdatingCompanyJobTypes={isUpdatingCompanyJobTypes}
                      options={serviceOptions}
                      onChangeServiceType={onChangeServiceType}
                    />
                    {!inAccountWizard && (
                      <>
                        <br />
                        <br />
                        <Payments buttonName="true" />
                      </>
                    )}
                  </TabPanel>
                  {!inAccountWizard && (
                    <TabPanel display="flex" flex={1} flexDirection="column">
                      <PersonalJobs data={data} />
                    </TabPanel>
                  )}
                </TabPanels>
              </Tabs>
            </Flex>
          )}
        </FullPageContent>
      </Layout>
      <UpdateRoles userId={data?.userId} />
      <RemoveFromTeam companyId={data?.companyId} userId={data?.userId} />
    </>
  );
};
