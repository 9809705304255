import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ProfilePresentation } from "../Common/ProfilePresentation";
import { PortalsContext } from "../../../contexts/PortalsContext";
import { useStatics } from "../../../api/modules/Statics";
import { useUser } from "../../../api/modules/User";
import {
  ProfileCompanyFormData,
  ProfileFormData,
  ProfileServiceFormData,
} from "../Common/ProfilePresentation/Profile.types";
import { useUpdateUser } from "../../../api/modules/UpdateUser";
import { ModalsContext } from "../../../contexts/ModalsContext";
import { useUpdateCompany } from "../../../api/modules/UpdateCompany";
import { useCompany } from "../../../api/modules/Company";
import { useUpdateCompanyJobTypes } from "../../../api/modules/UpdateCompanyJobTypes";
import { DEFAULT_LOCATION } from "./constants";
import { TCompanyLocation } from "../../../api/modules/Company/types";
import { useLocation } from "react-router-dom";
import { Button, Flex, useToast } from "@chakra-ui/react";

export const Profile = () => {
  const { pageTitleRef, breadcrumbsRef } = useContext(PortalsContext);

  const { updateRoles: updateRolesModal, removeFromTeam: removeFromTeamModal } =
    useContext(ModalsContext);
  const toast = useToast();
  const { isLoading: isLoadingStatics, data: staticsData } = useStatics();
  const { mutateAsync, isLoading } = useUpdateUser();
  const { mutateAsync: handleUpdateCompany, isLoading: isUpdatingCompany } =
    useUpdateCompany();
  const { data } = useUser();
  const { data: companyData } = useCompany();
  const {
    mutateAsync: updateCompanyJobTypes,
    isLoading: isUpdatingCompanyJobTypes,
  } = useUpdateCompanyJobTypes();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditCompanyMode, setIsEditCompanyMode] = useState(false);
  const [isEditServiceMode, setIsEditServiceMode] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [formData, setFormData] = useState<ProfileFormData>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    zip: "",
    state: "",
    septicSystemInspectionsLicense: "",
    plumbingLicense: "",
  });

  const [serviceFormData, setServiceFormData] =
    useState<ProfileServiceFormData>({
      companyLocations: [],
    });

  const pageLocation = useLocation();
  const [inAccountWizard, setInAccountWizard] = useState(false);
  const [isStripeConnected, setIsStripeConnected] = useState(false);

  const handleRemoveCompanyLocation = useCallback(
    (index: number) => () => {
      setServiceFormData((state) => ({
        ...state,
        companyLocations: serviceFormData.companyLocations.filter(
          (v, i) => i !== index
        ),
      }));
    },
    [serviceFormData.companyLocations, setServiceFormData]
  );

  const handleAddCompanyLocation = useCallback(() => {
    if (!companyData) return;
    setServiceFormData((state) => ({
      ...state,
      companyLocations: serviceFormData.companyLocations.concat(
        DEFAULT_LOCATION(companyData?.companyId)
      ),
    }));
  }, [companyData, serviceFormData.companyLocations, setServiceFormData]);

  useEffect(() => {
    if (!serviceFormData.companyLocations.length) {
      handleAddCompanyLocation();
    }
  }, [serviceFormData.companyLocations, handleAddCompanyLocation]);

  const [companyFormData, setCompanyFormData] =
    useState<ProfileCompanyFormData>({
      companyName: "",
      haveOwnInsurance: false,
      businessPhone: "",
      businessEmail: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      zip: "",
      state: "",
    });

  const handleSetEditMode = useCallback(() => {
    setIsEditMode(true);
    setFormData({
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      phoneNumber: data?.phoneNumber || "",
      email: data?.email || "",
      addressLine1: data?.addressLine1 || "",
      addressLine2: data?.addressLine2 || "",
      city: data?.city || "",
      zip: data?.zip || "",
      state: data?.state || "",
      septicSystemInspectionsLicense:
        data?.septicSystemInspectionsLicense || "",
      plumbingLicense: data?.plumbingLicense || "",
    });
  }, [setIsEditMode, setFormData, data]);
  const handleSetCompanyEditMode = useCallback(() => {
    setIsEditCompanyMode(true);
    setCompanyFormData({
      companyName: data?.company?.companyName || "",
      haveOwnInsurance: data?.company?.haveOwnInsurance || false,
      businessPhone: data?.company?.businessPhone || "",
      businessEmail: data?.company?.businessEmail || "",
      addressLine1: data?.company?.addressLine1 || "",
      addressLine2: data?.company?.addressLine2 || "",
      city: data?.company?.city || "",
      zip: data?.company?.zip || "",
      state: data?.company?.state || "",
    });
  }, [setCompanyFormData, setIsEditCompanyMode, data?.company]);

  const handleSetServiceEditMode = useCallback(() => {
    setIsEditServiceMode(true);
    setServiceFormData({
      companyLocations: companyData?.companyLocations || [],
    });
  }, [setIsEditServiceMode, setServiceFormData, companyData]);

  const handleCancelEditMode = useCallback(() => {
    setIsEditMode(false);
  }, [setIsEditMode]);

  const handleCancelCompanyEditMode = useCallback(() => {
    setIsEditCompanyMode(false);
  }, [setIsEditCompanyMode]);

  const handleCancelServiceEditMode = useCallback(() => {
    setIsEditServiceMode(false);
  }, [setIsEditServiceMode]);

  const handleEditField = useCallback(
    (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setFormData((state) => ({ ...state, [field]: e.target.value }));
    },
    [setFormData]
  );
  const handleEditCompanyField = useCallback(
    (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setCompanyFormData((state) => ({ ...state, [field]: e.target.value }));
    },
    [setCompanyFormData]
  );
  const handleEditServiceField = useCallback(
    (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setServiceFormData((state) => ({ ...state, [field]: e.target.value }));
    },
    [setServiceFormData]
  );
  const handleEditServiceLocationField = useCallback(
    (index: number, field: keyof TCompanyLocation) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        setServiceFormData((state) => ({
          ...state,
          companyLocations: state.companyLocations.map((location, i) => ({
            ...location,
            [field]: i === index ? e.target.value : location[field],
          })),
        }));
      },
    [setServiceFormData]
  );

  const handleEditCompanyCheckboxField = useCallback(
    (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setCompanyFormData((state) => ({ ...state, [field]: e.target.checked }));
    },
    [setCompanyFormData]
  );

  const handleDismissToast = useCallback(
    (id: string) => {
      toast.close(id);
    },
    [toast]
  );
  const handleToastPersonalNext = useCallback(() => {
    setTabIndex(1);
    handleDismissToast("personalSaved");
  }, [handleDismissToast, setTabIndex]);

  const toastIdRef = useRef<string | number | null>(null);
  const showProfilesaveToast = useCallback(() => {
    const id = "personalSaved";
    if (!toast.isActive(id)) {
      toastIdRef.current = toast({
        id,
        title: "Profile Information added",
        description: (
          <Flex gap={1} justifyContent="end" mr="16px" mt={3}>
            <Button
              color="green"
              size="xs"
              variant="ghost"
              onClick={() => handleDismissToast(id)}
            >
              Dismiss
            </Button>
            <Button
              color="green"
              size="xs"
              variant="ghost"
              onClick={() => handleToastPersonalNext()}
            >
              Next
            </Button>
          </Flex>
        ),
        position: "top-right",
        status: "success",
        isClosable: true,
        duration: 10000,
        variant: "subtle",
        containerStyle: { color: "#067647" },
      });
    }
  }, [toastIdRef, toast, handleDismissToast, handleToastPersonalNext]);

  const showWizardToast = useCallback(
    (id: string, description: string) => {
      if (!toast.isActive(id)) {
        toast({
          id,
          title: "",
          description: description,
          position: "top-right",
          status: "success",
          isClosable: true,
          duration: 10000,
          variant: "subtle",
          containerStyle: { color: "#067647" },
        });
      }
    },
    [toast]
  );

  const handleSaveProfile = useCallback(async () => {
    await mutateAsync(formData);
    if (inAccountWizard) {
      showProfilesaveToast();
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [
    formData,
    mutateAsync,
    setIsEditMode,
    inAccountWizard,
    showProfilesaveToast,
  ]);

  const handleSaveCompany = useCallback(async () => {
    await handleUpdateCompany({
      ...data?.company,
      ...companyFormData,
    });
    if (inAccountWizard) {
      showWizardToast("companySaved", "Company Information added");
      setIsEditCompanyMode(true);
      setIsEditServiceMode(true);
    } else {
      setIsEditCompanyMode(false);
      setIsEditServiceMode(false);
    }
  }, [
    data?.company,
    setIsEditCompanyMode,
    handleUpdateCompany,
    companyFormData,
    inAccountWizard,
    showWizardToast,
  ]);

  const [isNextClicked, setIsNextClicked] = useState(false);
  const handlePersonalNext = useCallback(async () => {
    await mutateAsync(formData);
    if (inAccountWizard) {
      setIsEditMode(true);
      setTabIndex(1);
    }
    setIsNextClicked(false);
  }, [formData, mutateAsync, setIsEditMode, inAccountWizard, setIsNextClicked]);

  const handleSaveService = useCallback(async () => {
    await handleUpdateCompany({
      ...data?.company,
      ...serviceFormData,
    });
    setIsEditCompanyMode(false);
    setIsEditServiceMode(false);
  }, [
    serviceFormData,
    data?.company,
    setIsEditCompanyMode,
    handleUpdateCompany,
  ]);

  const serviceOptions = (staticsData?.jobTypes || []).reduce<
    { label: string; value: number }[]
  >((acc, service) => {
    acc.push({ value: service.id, label: service.title });

    (service.relatedJobTypes || []).forEach((relatedJob) => {
      acc.push({ value: relatedJob.id, label: relatedJob.title });
    });
    return acc;
  }, []);

  const handleChangeServiceType = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      if (e.target.checked) {
        const updatedJobTypes = (companyData?.companyJobTypes || []).concat(
          parseInt(e.target.name)
        );
        updateCompanyJobTypes(updatedJobTypes);
      } else {
        const updatedJobTypes = (companyData?.companyJobTypes || []).filter(
          (service) => service !== parseInt(e.target.name)
        );
        updateCompanyJobTypes(updatedJobTypes);
      }
    },
    [companyData?.companyJobTypes, updateCompanyJobTypes]
  );
  const isServiceDetailsAvailable = data?.companyId === companyData?.companyId;

  useEffect(() => {
    const queryParams = new URLSearchParams(pageLocation.search);
    const hasQueryString = queryParams.keys().next().done === false;
    if (hasQueryString) {
      const accountWizard = queryParams.get("account-wizard");
      const stripeConnected = queryParams.get("stripe-connected");
      if (accountWizard === "true") {
        setInAccountWizard(true);
        handleSetEditMode();
        handleSetCompanyEditMode();
      } else {
        setInAccountWizard(false);
      }

      if (stripeConnected && companyData?.stripeAccountStatus === "complete") {
        setIsStripeConnected(true);
      }
      if (isStripeConnected) {
        setTabIndex(1);
        const id = "stripe-connected";
        showWizardToast(id, "Stripe connected");
      }
    }
  }, [
    pageLocation,
    handleSetEditMode,
    handleSetCompanyEditMode,
    isStripeConnected,
    showWizardToast,
    companyData?.stripeAccountStatus,
  ]);

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const handleCompanyBack = () => {
    setTabIndex(0);
  };

  return (
    <ProfilePresentation
      activeServiceOptions={companyData?.companyJobTypes || []}
      breadcrumbsRef={breadcrumbsRef}
      companyData={companyData}
      companyFormData={companyFormData}
      data={data}
      formData={formData}
      inAccountWizard={inAccountWizard}
      isCompanyEditable={true}
      isEditCompanyMode={isEditCompanyMode}
      isEditMode={isEditMode}
      isEditServiceMode={isEditServiceMode}
      isEditable={true}
      isLoading={isLoadingStatics}
      isServiceDetailsAvailable={isServiceDetailsAvailable}
      isStripeConnected={isStripeConnected}
      isUpdatingCompany={isUpdatingCompany}
      isUpdatingCompanyJobTypes={isUpdatingCompanyJobTypes}
      isUpdatingProfile={isLoading && !isNextClicked}
      isUpdatingProfileNext={isLoading && isNextClicked}
      pageTitleRef={pageTitleRef}
      removeFromTeam={removeFromTeamModal}
      serviceFormData={serviceFormData}
      serviceOptions={serviceOptions}
      statics={staticsData}
      tabIndex={tabIndex}
      updateRolesModal={updateRolesModal}
      onAddCompanyLocation={handleAddCompanyLocation}
      onCancelCompanyEditMode={handleCancelCompanyEditMode}
      onCancelEditMode={handleCancelEditMode}
      onCancelServiceEditMode={handleCancelServiceEditMode}
      onChangeServiceType={handleChangeServiceType}
      onCompanyBack={handleCompanyBack}
      onEditCompanyCheckboxField={handleEditCompanyCheckboxField}
      onEditCompanyField={handleEditCompanyField}
      onEditField={handleEditField}
      onEditServiceField={handleEditServiceField}
      onEditServiceLocationField={handleEditServiceLocationField}
      onPersonalNext={() => {
        setIsNextClicked(true);
        handlePersonalNext();
      }}
      onRemoveCompanyLocation={handleRemoveCompanyLocation}
      onSaveCompany={handleSaveCompany}
      onSaveProfile={handleSaveProfile}
      onSaveService={handleSaveService}
      onSetEditCompanyMode={handleSetCompanyEditMode}
      onSetEditMode={handleSetEditMode}
      onSetEditServiceMode={handleSetServiceEditMode}
      onTabChange={handleTabChange}
    />
  );
};
