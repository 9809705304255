import { Offerings, Package, PurchasesError } from "@revenuecat/purchases-js";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Routing } from "../../constants/routing";
import { useLogout } from "../../utils/logout";
import { getUserRevenueCatId } from "../../utils/User/getUserRevenueCatId";
import { PayWallPresentation } from "./PayWall.presentation";
import { IPaywallProps } from "./PayWall.types";

export const PayWall = ({ purchases }: IPaywallProps) => {
  const [offerings, setOfferings] = useState<Offerings | null>(null);
  const navigate = useNavigate();
  const handleLogout = useLogout();

  useEffect(() => {
    purchases.changeUser(getUserRevenueCatId() as string);
    purchases.getOfferings().then((offering) => {
      setOfferings(offering);
    });
  }, [purchases]);

  const handleStartPurchase = useCallback(
    async (planPackageId: string) => {
      const pkg = offerings?.all["web"].availablePackages.find(
        (p) => p.identifier === planPackageId
      ) as Package;

      if (!pkg.rcBillingProduct) {
        return;
      }

      try {
        const { customerInfo } = await purchases.purchasePackage(pkg);
        navigate(`${Routing.Profile}?account-wizard=true`);
        console.log(`CustomerInfo after purchase: ${customerInfo}`);
      } catch (e) {
        if (e instanceof PurchasesError) {
          console.log(`Error performing purchase: ${e}`);
          // TODO: We should display an error here.
        } else {
          console.error(`Unknown error: ${e}`);
        }
      }
    },
    [offerings, purchases, navigate]
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const planPackageId = queryParams.get("planPackageId");

    if (planPackageId && offerings) {
      handleStartPurchase(planPackageId);
    }
  }, [offerings, handleStartPurchase]);

  return (
    <PayWallPresentation
      offerings={offerings}
      onLogout={handleLogout}
      onStartPurchase={handleStartPurchase}
    />
  );
};
