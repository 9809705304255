import React, { useCallback, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Flex,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import { Props } from "./ContactInformation.types";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { PHONE_PATTERN } from "../../../../constants/global";
import { PatternFormat } from "react-number-format";
import { useUploadProfilePhoto } from "../../../../api/modules/UploadProfilePhoto";
import { getProfileImageUrl } from "../../../../utils/getProfileImageUrl";

export const ContactInformation = (props: Props) => {
  const screen = translations.screens.profile;
  const { isUpdatingProfile, isEditMode, data, onEditField, formData } = props;
  const { firstName, lastName, phoneNumber, email, profilePicUrl } = data || {};
  const { mutateAsync: handleUploadProfilePhoto } = useUploadProfilePhoto();
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [profile64PicUrl, setProfile64PicUrl] = useState(String);

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64StringImage = (reader.result as string).split(",")[1];
        setProfile64PicUrl(`data:image/*;base64,${base64StringImage}`);
        await handleUploadProfilePhoto({ base64StringImage });
      };
      reader.readAsDataURL(file);
    },
    [handleUploadProfilePhoto]
  );

  return (
    <TableContainer maxWidth="100%" width="100%">
      <Table variant="simple">
        <Tbody fontSize="sm">
          <Tr>
            <Td
              fontWeight={500}
              paddingLeft={0}
              verticalAlign="text-top"
              width="218px"
            >
              {t<string>(screen.table.profile)}
            </Td>
            <Td color="gray.500">
              <Flex>
                <Avatar
                  bg="gray.200"
                  size="xl"
                  src={getProfileImageUrl({
                    profilePicUrl:
                      profilePicUrl !== null ? profilePicUrl : profile64PicUrl,
                  })}
                  onClick={() => inputFileRef.current?.click()}
                />
              </Flex>
              <Flex ml="-8px" mt="16px">
                <Input
                  ref={inputFileRef}
                  accept="image/*"
                  display="none"
                  id="file-input"
                  type="file"
                  onChange={handleFileChange}
                />
                <Button
                  colorScheme="gray"
                  size="xs"
                  onClick={() => inputFileRef.current?.click()}
                >
                  Add Profile Photo
                </Button>
              </Flex>
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight={500} paddingLeft={0} width="218px">
              {t<string>(screen.table.firstName)}
            </Td>
            <Td color="gray.500">
              {isEditMode ? (
                <Input
                  disabled={isUpdatingProfile}
                  value={formData.firstName}
                  width="360px"
                  onChange={onEditField("firstName")}
                />
              ) : (
                firstName || "-"
              )}
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight={500} paddingLeft={0} width="218px">
              {t<string>(screen.table.lastName)}
            </Td>
            <Td color="gray.500">
              {isEditMode ? (
                <Input
                  disabled={isUpdatingProfile}
                  value={formData.lastName}
                  width="360px"
                  onChange={onEditField("lastName")}
                />
              ) : (
                lastName || "-"
              )}
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight={500} paddingLeft={0} width="218px">
              {t<string>(screen.table.phone)}
            </Td>
            <Td color="gray.500">
              {isEditMode && (
                <PatternFormat
                  customInput={Input}
                  defaultValue={formData.phoneNumber}
                  disabled={isUpdatingProfile}
                  format={PHONE_PATTERN}
                  width="360px"
                  onValueChange={(v) => {
                    onEditField("phoneNumber")({
                      target: { value: v.value },
                    } as any);
                  }}
                />
              )}
              {!isEditMode && (
                <PatternFormat
                  displayType="text"
                  format={PHONE_PATTERN}
                  value={phoneNumber}
                  width="360px"
                />
              )}
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight={500} paddingLeft={0} width="218px">
              {t<string>(screen.table.email)}
            </Td>
            <Td color="gray.500">{email || "-"}</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
