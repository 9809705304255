import React, { useRef, useState, useCallback } from "react";
import {
  Avatar,
  Button,
  Flex,
  FormControl,
  // FormLabel,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import { Props } from "./CompanyData.types";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { getAddressCopy } from "../../../../utils/Company/getAddressCopy";
import { PatternFormat } from "react-number-format";
import { PHONE_PATTERN } from "../../../../constants/global";
import { useUploadProfilePhoto } from "../../../../api/modules/UploadProfilePhoto";
import { getProfileImageUrl } from "../../../../utils/getProfileImageUrl";

export const CompanyData = (props: Props) => {
  const screen = translations.screens.profile.companyDetailsData;
  const {
    data,
    formData,
    onEditField,
    isEditMode,
    isUpdatingCompany,
    companyLogoUrl,
  } = props;
  const { companyName, businessPhone, businessEmail, companyId } = data || {};

  const { mutateAsync: handleUploadProfilePhoto } = useUploadProfilePhoto();
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [profile64PicUrl, setProfile64PicUrl] = useState(String);

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64StringImage = (reader.result as string).split(",")[1];
        setProfile64PicUrl(`data:image/*;base64,${base64StringImage}`);
        await handleUploadProfilePhoto({
          base64StringImage,
          isCompanyLogo: true,
          companyId: companyId,
        });
      };
      reader.readAsDataURL(file);
    },
    [handleUploadProfilePhoto, companyId]
  );

  return (
    <TableContainer maxWidth="100%" width="100%">
      <Table variant="simple">
        <Tbody fontSize="sm">
          <Tr>
            <Td fontWeight={500} paddingLeft={0} width="218px">
              {t<string>(screen.title)}
            </Td>
            <Td color="gray.500">
              {isEditMode ? (
                <Input
                  disabled={isUpdatingCompany}
                  value={formData.companyName}
                  width="360px"
                  onChange={onEditField("companyName")}
                />
              ) : (
                companyName || "-"
              )}
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight={500} paddingLeft={0} width="218px">
              {t<string>(screen.phone)}
            </Td>
            <Td color="gray.500">
              {isEditMode && (
                <PatternFormat
                  customInput={Input}
                  defaultValue={formData.businessPhone}
                  disabled={isUpdatingCompany}
                  format={PHONE_PATTERN}
                  width="360px"
                  onValueChange={(v) => {
                    onEditField("businessPhone")({
                      target: { value: v.value },
                    } as any);
                  }}
                />
              )}
              {!isEditMode && (
                <PatternFormat
                  displayType="text"
                  format={PHONE_PATTERN}
                  value={businessPhone}
                />
              )}
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight={500} paddingLeft={0} width="218px">
              {t<string>(screen.email)}
            </Td>
            <Td color="gray.500">
              {isEditMode ? (
                <Input
                  disabled={isUpdatingCompany}
                  value={formData.businessEmail}
                  width="360px"
                  onChange={onEditField("businessEmail")}
                />
              ) : (
                businessEmail || "-"
              )}
            </Td>
          </Tr>
          <Tr>
            <Td
              fontWeight={500}
              paddingLeft={0}
              verticalAlign="top"
              width="218px"
            >
              {t<string>(screen.address)}
            </Td>
            {/*<Td color="gray.500">{t<string>(screen.companyAddress1)}</Td>*/}
            <Td color="gray.500">
              {isEditMode ? (
                <Flex flexDirection="column">
                  <FormControl>
                    {/* <FormLabel fontSize="sm" htmlFor="addressLine1">
                      {t<string>(screen.companyAddress1)}
                    </FormLabel> */}
                    <Input
                      disabled={isUpdatingCompany}
                      placeholder="Street Address 1"
                      value={formData.addressLine1}
                      width="360px"
                      onChange={onEditField("addressLine1")}
                    />
                  </FormControl>
                  <FormControl mt={2}>
                    {/* <FormLabel fontSize="sm" htmlFor="companyAddress2">
                      {t<string>(screen.companyAddress2)}
                    </FormLabel> */}
                    <Input
                      disabled={isUpdatingCompany}
                      placeholder="Street Address 2"
                      value={formData.addressLine2}
                      width="360px"
                      onChange={onEditField("addressLine2")}
                    />
                  </FormControl>
                  <FormControl mt={2}>
                    {/* <FormLabel fontSize="sm" htmlFor="city">
                      {t<string>(screen.city)}
                    </FormLabel> */}
                    <Input
                      disabled={isUpdatingCompany}
                      placeholder="City"
                      value={formData.city}
                      width="360px"
                      onChange={onEditField("city")}
                    />
                  </FormControl>
                  <FormControl mt={2}>
                    {/* <FormLabel fontSize="sm" htmlFor="state">
                      {t<string>(screen.state)}
                    </FormLabel> */}
                    <Flex direction="row" gap={2}>
                      <Input
                        disabled={isUpdatingCompany}
                        placeholder="State"
                        value={formData.state}
                        width="176px"
                        onChange={onEditField("state")}
                      />
                      <Input
                        disabled={isUpdatingCompany}
                        placeholder="ZIP Code"
                        value={formData.zip}
                        width="176px"
                        onChange={onEditField("zip")}
                      />
                    </Flex>
                  </FormControl>
                </Flex>
              ) : (
                getAddressCopy(data)
              )}
            </Td>
          </Tr>
          <Tr>
            <Td
              fontWeight={500}
              paddingLeft={0}
              verticalAlign="top"
              width="218px"
            >
              {t<string>(screen.companyLogo)}
            </Td>
            <Td color="gray.500">
              <Flex>
                <Avatar
                  bg="gray.200"
                  size="xl"
                  src={getProfileImageUrl({
                    profilePicUrl:
                      companyLogoUrl !== null
                        ? companyLogoUrl
                        : profile64PicUrl,
                  })}
                  onClick={() => inputFileRef.current?.click()}
                />
              </Flex>
              <Flex ml="4px" mt="16px">
                <Input
                  ref={inputFileRef}
                  accept="image/*"
                  display="none"
                  id="file-input"
                  type="file"
                  onChange={handleFileChange}
                />
                <Button
                  colorScheme="gray"
                  size="xs"
                  onClick={() => inputFileRef.current?.click()}
                >
                  Upload Logo
                </Button>
              </Flex>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
